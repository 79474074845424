import Store from "../../store";

class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file) =>
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve, reject) => {
          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              // reader.readAsBinaryString(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });

          let formData = new FormData();
          formData.append("file", file);
          const response = await Store.dispatch("news/uploadMedia", formData, {
            root: true,
          });
          if (response.data.data.id) {
            this.loader.uploaded = true;
            resolve({
              default: response.data.data.url,
            });
          } else {
            reject(`Couldn't upload file: ${file.name}.`);
          }
          return toBase64(file);
        })
    );
  }
}

export default function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}
