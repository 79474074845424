<template>
  <v-card>
    <v-card-title>
      <v-btn
        dark
        small
        dense
        elevation="4"
        @click="$router.go(-1)"
        min-height="40"
        color="primary"
        class="ml-4 mr-4 pr-4"
      >
        <v-icon size="25" class="white--text text--darken-4 mr-2">
          mdi-arrow-left-bold
        </v-icon>
        {{ $t("news.back") }}
      </v-btn>
      <span class="headline">{{
        !selectedNews.id ? $t("news.addNews") : $t("news.editNews")
      }}</span>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="selectedNews.title"
              :label="$t('news.title')"
              :placeholder="$t('news.title')"
              @input="$v.selectedNews.title.$touch()"
              @blur="$v.selectedNews.title.$touch()"
              :error-messages="titleErrors"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <ckeditor
              :editor="editor"
              v-model="selectedNews.description"
              :config="editorConfig"
              @ready="onReady"
              @input="$v.selectedNews.description.$touch()"
              @blur="$v.selectedNews.description.$touch()"
            ></ckeditor>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-4 mr-4 pr-4 pl-4 mb-5"
        color="primary"
        :loading="saving"
        :disabled="
          ($v.selectedNews.$invalid && $v.selectedNews.$anyDirty) || saving
        "
        @click="save"
        dark
        large
        dense
        elevation="4"
      >
        Save
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { showSnackbar } from "../../eventbus/action.js";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import MyCustomUploadAdapterPlugin from "./ckImageUploader";

export default {
  name: "CreateAndEditNews",
  props: {
    selectedNewsData: {
      required: false,
      type: Object,
      default: () => {
        return {
          id: null,
          title: null,
          description: "",
          media_ids: [],
        };
      },
    },
  },
  data() {
    return {
      selectedNews: this.selectedNewsData,
      editor: DecoupledEditor,
      editorConfig: {
        placeholder: "Document contents..",
        link: {
          addTargetToExternalLinks: true,
        },
        extraPlugins: [MyCustomUploadAdapterPlugin],
      },
      saving: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedMedia: "news/getNewsMediaResponse",
    }),
    titleErrors() {
      const errors = [];
      if (!this.$v.selectedNews.title.$dirty) return errors;
      !this.$v.selectedNews.title.required &&
        errors.push(this.$t("news.validations.titleIsRequired"));
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.selectedNews.description.$dirty) return errors;
      !this.$v.selectedNews.description.required &&
        errors.push(this.$t("news.validations.titleIsRequired"));
      return errors;
    },
  },
  watch: {
    selectedMedia(newMedia) {
      this.selectedNews.media_ids.push(newMedia.data.id);
    },
  },
  validations: {
    selectedNews: {
      title: { required },
      description: { required },
    },
  },

  methods: {
    ...mapActions({
      updateNews: "news/updateNews",
      createNews: "news/createNews",
    }),

    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
      );
    },

    close() {
      this.$router.go(-1);
    },

    async save() {
      this.$v.selectedNews.$touch();
      if (!this.$v.selectedNews.$invalid) {
        try {
          this.saving = true;
          let payload = _.cloneDeep(this.selectedNews);
          if (payload.id) {
            await this.updateNews({
              ...payload,
            });
          } else {
            await this.createNews({
              ...payload,
            });
          }
          showSnackbar({ success: true, message: "News Added Successfully" });
          setTimeout(() => {
            this.close();
          }, 1500);
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showSnackbar(errorMessage);
        } finally {
          setTimeout(() => {
            this.saving = false;
          }, 2000);
        }
      }
    },
  },
};
</script>
<style>
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
</style>
<style>
.ck .ck-reset .ck-editor .ck-rounded-corners {
  min-height: 500px !important;
  border: 1px groove rgb(195, 197, 195) !important;
}
.ck-editor__editable {
  min-height: 500px !important;
  border: 1px groove rgb(195, 197, 195) !important;
}

.ck-editor__editable_inline {
  min-height: 500px !important;
  border: 1px groove rgb(195, 197, 195);
}

:host ::ng-deep .ck-editor__editable_inline {
  min-height: 500px !important;
  border: 1px groove rgb(195, 197, 195) !important;
}
</style>
